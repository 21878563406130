html {
  --fallback-fonts: Helvetica Neue,helvetica,arial,Hiragino Kaku Gothic ProN,Meiryo,MS Gothic
}

body {
  -webkit-font-smoothing: antialiased !important;
  background-color: #121212 !important;
  color: white !important;
  font-family: var(--encore-body-font-stack,var(--fallback-fonts,sans-serif)),sans-serif !important;
}

.status {
  min-width: 35px;
  display: flex;
  justify-content: center;
}

.status-sidebar {
  min-width: 50px;
  display: flex;
  justify-content: center;
}

/* SIDEBAR.JSX */

/* minor letter color :rgb(179, 179, 179) */



.sidebar-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-radius: 5px;
  padding: 10px;
  max-width: max-content;
  background-color:  #191919;
  max-height: fit-content;

}

.sidebar-item {
  border-radius: 5px;
  padding: 10px;
  display: flex;
  user-select: none;
}

.sidebar-item:hover {
  background-color: rgb(123, 120, 120);
  cursor: pointer;
  
}

.sidebar-item-inner {
  display: flex;
  flex-direction: column;
}

.sidebar-item img{ 
  padding: 1px;
  background-color: white;
  border-radius: 2px;
  max-width: 40px !important;
  max-height: 40px !important;
  margin-right: 10px;

}



/* FIXTURES.JSX */

.fixtures-wrapper{
  display:flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 100px;
}

.calendar-datepicker{
  border: none;
  border-color: white !important;
  border-width: 1px;
  border-radius: 5px;
  background: black;
  cursor: pointer;
  width: 30px; /* Adjust based on the size of the calendar icon */
  height: 30px; /* Adjust based on the size of the calendar icon */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
}

.calendar-datepicker::-webkit-calendar-picker-indicator{
  background-size: cover; 
  cursor: pointer;
}

.calendar {
  min-width: 20vw;
  display:flex;
  flex-direction: row;
  gap: 25px;
  background-color: #191919;
  border-radius: 5px;
  align-items: center;
  justify-content: space-evenly;
}

.calendar-icon {
  position: relative;
  top: 1px;
  right: 26px;
  pointer-events: none;
}

.calendar-btn {
 display:flex;
 flex-direction: column;
 padding: 5px;
 border-radius: 5px;
}

.calendar-btn:hover{
  cursor: pointer;
}

.calendar-btn-major {
  margin: auto;
  color: rgb(179, 179, 179);
}  

.calendar-btn-minor {
  font-weight: lighter;
  font-size: small;
  color: rgb(179, 179, 179);
}


.fixtures{
  display:flex !important;
  flex-direction: column;
  background-color: #121212;
  padding:10px;
  border-radius: 5px;
  gap: 1px;
}

.fixture {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #222222;
  border-radius: 6px;
  margin-bottom: 3px;
}

.fixture:hover {
  background-color: rgb(123, 120, 120);
  border-radius: 5px;
  cursor: pointer;
}



.datetime{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:rgb(179, 179, 179);
  font-size: small;
}

.FT{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  color:rgb(179, 179, 179);
}

.live {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #C70039;
  gap: 7px;
}

.live-banner{
  background-color: #C70039;
  width: 2px;
  height: 55px;
  border-radius: 5px;
}

.date{
  color: rgb(179, 179, 179);
}

.h2h {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.teams img{
  width: 30px;
  height: 30px;
}

.scores{
 display: flex;
 flex-direction: column;
 justify-content: space-around;
}

.fixtures-block-1 {
  display: flex;
  flex-direction: row;
  padding:10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.fixtures-block-1:hover{
  cursor: pointer;
}


/* Fixture.jsx  */

.name {
  margin-top: 5px;
  font-weight: bold;
}

.match-wrapper {
  display:flex;
  flex-direction: column;
  background-color: #191919;
  padding:10px;
  border-radius: 5px;
  gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

.block-1 {
  display: flex;
  flex-direction: row;
}

.block-1:hover{
  cursor: pointer;
}

.block-2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.block-3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.block-4 {
  margin-top: 20px;
}

.fixture-team{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fixture-score{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.invisible {
  user-select: none;
  color: #191919;
}

.fixture-league-logo {
  padding: 1px;
  background-color: white;
  border-radius: 2px;
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
}

.fixture-stadium-logo{
  max-width: 30px;
  max-height: 30px;
  margin-right: 18px;
  margin-left: 2px;
}

.fixture-calendar-logo{
  max-width: 30px;
  max-height: 30px;
}

.ml10 {
  margin-left: 15px;
}

/* Leagues.jsx */

.leagues-wrapper {
  display: flex;
  flex-direction: column;
}

/* App.jsx */

.app-wrapper{
  margin-top: 50px;  
  display: flex;
  gap: 10px;
  justify-content: center;
}

/* Header.jsx */
.header{
  padding: 25px;
  /* padding: 10px; */
  /* padding-top: 20px; */
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items:flex-end;
}

.header div:hover{
  cursor: pointer;
}

.misc{
  font-size: 12pt;
  font-weight: 2rem;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.line{
  background-color: white;
  margin: 10px;
  height: 3px;
  background-color: #C70039;
}

/* Footer.jsx */

.footer{
  margin-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:rgba(178, 20, 20, 0.9);
}

.footer:hover h5{
  cursor: pointer !important;
}

.footer-info {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
}

.contact-us-footer{
  color: white !important;
  text-decoration: none !important;
}

/* About.jsx */

.about-wrapper {
  display:flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 100px;
}

.field-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  /* border: solid;
  border-color: white;
  border-width: 2px;
  border-radius: 5px; */
}

/* Main field container */
.field {
  color: white;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #28a745; 
  width: 400px; 
  height: 600px; 
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  border: solid;
  border-color: white;
  border-width: 2px;
  border-radius: 5px;
}

/* Center line */
.field::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: translateY(-50%);
}

/* Center circle (smaller and outline only) */
.field::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px; /* Adjusted size */
  height: 80px; /* Adjusted size */
  border: 2px solid white; /* White outline for the center circle */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

/* Goal areas (at the top and bottom of the field) */
.goal-area {
  position: absolute;
  width: 100px; /* Adjusted width */
  height: 35px; /* Adjusted height */
  left: 50%;
  transform: translateX(-50%);
}

/* Top goal area */
.top-goal {
  top: 0;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
}

/* Bottom goal area */
.bottom-goal {
  bottom: 0;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
}

/* Penalty box (larger area around the goal) */
.penalty-area:nth-child(odd) {
  position: absolute;
  width: 220px; /* Adjusted width */
  height: 70px; /* Adjusted height */
  border: 2px solid white;
  border-top: none;
  left: 50%;
  transform: translateX(-50%);
}

.penalty-area:nth-child(even) {
  position: absolute;
  width: 220px; /* Adjusted width */
  height: 70px; /* Adjusted height */
  border: 2px solid white;
  border-bottom: none;
  left: 50%;
  transform: translateX(-50%);
}


.top-penalty {
  top: 0;
}

.bottom-penalty {
  bottom: 0;
}

/* Columns representing player positions */
.lineups {
  font-size: 10pt;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%; /* Adjust to full width for vertical field */
  height: 50%;
}


.player-wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.player-wrapper div{
  min-height: 35px;
  display: flex;
  justify-content: space-evenly;
}

.top-wrapper {
  margin-top: 55px;
}

.bottom-wrapper {
  margin-bottom: 25px;
}

/* Styling for each player */
.player {
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;
  align-items: center;
  color: white ;
  font-weight: bold ;
  text-align: center;
}


/* Center the goalkeeper in their goal */
.goalkeeper {
  display: flex;
  justify-content: center; 
  align-items: center;
}
.mj {
  text-align: center;
  color: rgb(179, 179, 179);
}

.ads {
  width: 200px;
  height: 600px;
}

.slider-wrapper {
  display: none;
}

.navbar {
  display: none;
  z-index: 999;
}

.slider-wrapper{
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
}

.slider-item {
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  gap: 7px;
}
.slider-image {
  width: 50px;
  border-radius: 2px;
  background-color: white;
  padding: 1px;
}

.slider-1 {
  max-width: 100px !important;
}

.slider-2 {
  display: flex;
  flex-direction: column;
}


@media (max-width: 925px){

  .navbar {
    display: block;
  }

  .app-wrapper{
    display: block;
  }

  .calendar{
    min-width: none;
    margin: auto;
    width: 90% !important;
    gap: none;
  }

  .sidebar-wrapper{
    display: none;
  }
  .ads{
    display: none;
  }

  .navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .h2h {
    display: flex;
    flex-direction: column;
    width: 89%;
  }

  .footer{
    margin-bottom: 55px;
    padding-left: 15px;
    padding-right: 15px;
    height: 75px;
    display: flex;
    justify-content: space-between;
    background-color:rgba(178, 20, 20, 0.9);
  }
  
  .footer-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

}


@media (max-width: 474px) {

  .home-team-logo {
    max-width: 125px !important;
  }

  .away-team-logo {
    max-width: 125px !important;
  }

  .netscore-logo {

  }

  #id0 {
    display: none;
  }

  .calendar {
    gap:10px;
  }

  .calendar-btn-major{
    font-size: 17px;
    margin-bottom: 2px;
  }

  .calendar-btn-minor{
    font-size: 12px;
  }

}

/* Basic styling for the main content */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.main-content {
  padding: 20px;
}

/* Navbar styling */
.navbar {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

.navbar-toggle {
  cursor: pointer;
  font-size: 1.2em;
}

/* Overlay menu styling */
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto; /* Enables scrolling if the content is long */
  z-index: 1000;
}

.overlay-content {
  color: white;
  padding: 20px;
  text-align: center;
}

.overlay-content ul {
  list-style-type: none;
  padding: 0;
}

.overlay-content ul li {
  margin: 15px 0;
}

.overlay-content ul li a {
  color: white;
  font-size: 1.5em;
  text-decoration: none;
}

.close-btn {
  font-size: 1.5em;
  cursor: pointer;
  display: block;
  text-align: right;
}


/* Overlay menu styling with animation */
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 100%; /* Start from below the screen */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
}

/* Show the overlay with animation */
.overlay.active {
  display: block;
  animation: slide-up 0.4s ease forwards;
  opacity: 1;
}

/* Define the slide-up animation */
@keyframes slide-up {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

.overlay-content {
  color: white;
  padding: 20px;
  text-align: center;
}

.overlay-content ul {
  list-style-type: none;
  padding: 0;
}

.overlay-content ul li {
  margin: 15px 0;
}

.overlay-content ul li a {
  color: white;
  font-size: 1.5em;
  text-decoration: none;
}

.close-btn {
  font-size: 1.5em;
  cursor: pointer;
  display: block;
  text-align: right;
}

/* STATS */

.block-5 {
  margin-top: 40px;
}

.stats-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.stat-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1%;
  outline: 1px solid gray;
  min-height: 38px;
  padding: 2px;
  padding-left: 3px;
}

.stat-l{
  display: flex;
  flex-direction: column;
  min-width: 35%;
  padding-left: 10px;
}

.stat-time {
  min-width: 21px;
}

.stat-r{
  display: flex;
  flex-direction: column;
  min-width: 35%;
  padding-left: 10px;
}

.stat-player {
  display: flex;
  flex-direction: column;
}

.stat-assist{
  font-weight: lighter;
  color: gray;
}

.stat-ft {
  display: flex;
  align-items: center;
  justify-content: space-around ;
  align-items: center;
  gap: 30%;
  outline: 1px solid gray;
  min-height: 38px;
}

.subst-wrapper {
  display: flex;
  flex-direction: column;
}

.circle-green {
  background-color: #28a745;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}

.circle-red {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}

.yellow-card {
  background-color: yellow;
  width: 20px;
  height: 22px;
  border-radius: 3px;
}

.red-card {
  background-color: red;
  width: 20px;
  height: 22px;
  border-radius: 3px;
}

.sub-in {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sub-out {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* About.jsx */

.misc-wrapper {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}

.misc-wrapper h3{
  color:rgba(208, 8, 8, 0.9);
}

.misc-wrapper h1{
  color:rgba(208, 8, 8, 0.9);
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #191919;
  border-radius: 5px;
}
.tos-wrapper {

  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #191919;
  border-radius: 5px;

}

/* Cookie consent banner */

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #222;
  color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1000;
}

.cookie-banner p {
  text-align: left;
  margin: 0 0 10px;
  font-size: 14px;
}

.cookie-banner a {
  color: #4da6ff;
  text-decoration: underline;
}

.button-group {
  display: flex;
  gap: 10px;
}

button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.accept {
  background-color: #4caf50;
  color: white;
}

.accept:hover {
  background-color: #45a049;
}

.decline {
  background-color: #d9534f;
  color: white;
}

.decline:hover {
  background-color: #c9302c;
}

/* Predictions */

.head-wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.win-rates-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.predictions-date-minor {
  font-size: 8pt;
}

.predictions-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* General */

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.text-major{
  font-weight: bolder;
  font-size: 15pt;
}

.text-minor {
  font-size: 10pt;
  font-weight: lighter;
  color: rgb(179, 179, 179);
}