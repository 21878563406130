  @media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
  }
  
  body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  text-align: var(--bs-body-text-align);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
  }
  
  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
  }
  
  h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
  }
  @media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
  }
  
  h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
  }
  @media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
  }
  
  h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
  }
  
  h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
  }
  
  h5, .h5 {
  font-size: 1.25rem;
  }
  
  h6, .h6 {
  font-size: 1rem;
  }
  
  p {
  margin-top: 0;
  margin-bottom: 1rem;
  }

  img,
  svg {
  vertical-align: middle;
  }
  
  @media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
  }
  legend + * {
  clear: left;
  }
  
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
  padding: 0;
  }
  
  ::-webkit-inner-spin-button {
  height: auto;
  }
  
  [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
  }
  

